<template>
  <div class="content">
    <div v-if="isData" class="noDataWrap">
      <img
        class="noDataImg"
        src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/23/yuelvhui680UxFT6uf1608649312.png"
        alt=""
      >
      <p class="noDataText">您还没有相关订单</p>
    </div>
    <div v-else class="OrderListContent">
      <div v-for="(item,index) in orderList" :key="index" class="orderInfo">
        <div class="infoNameStatus">
          <div class="infoName">
            <p>{{ item.name1 }}</p>
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-24/22/yuelvhuiEe06qCRO2n1608818577.png"
              alt=""
            >
            <p>{{ item.name2 }}</p>
          </div>
          <div class="infoStatus">
            <p v-if="item.status === 5" style="color:#999999 ">{{ item.statusText }}</p>
          </div>
        </div>
        <div class="infoTime">
          <p>{{ item.time }}</p>
          <p>{{ item.Model }} / {{ item.DepartureSite }}</p>
        </div>
        <div class="infoDate">
          <p>{{ item.createTime }}</p>
          <p>支付金额：{{ item.price }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrainOrder',
  data() {
    return {
      mid: '',
      page: 1,
      orderList: [{
        name1: '北京西',
        name2: '临汾西',
        status: 5,
        statusText: '已取消',
        time: '2020-04-07 18:40 至 21:00',
        Model: 'Hu7635',
        DepartureSite: '首都T2航站楼出发',
        createTime: '2020-04-06 01:10:39',
        price: 49.6
      }]
    }
  },
  created() {

  },

  mounted() {
    this.mid = window.localStorage.getItem('uid')
    this.getTrainOrderList(0)
  },
  methods: {
    // 火车票
    getTrainOrderList(status) {

    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}

.content {
  position: absolute;
  text-align: left;

  .noDataWrap {
    text-align: center;
    margin-top: 150px;

    .noDataImg {
      width: 150px;
      height: 150px;
    }

    .noDataText {
      font-size: 14px;
      color: #ccc;
      margin-top: 20px;
    }
  }

  .OrderListContent {
    padding: 10px 13px;

    .orderInfo {
      width: 100%;
      padding: 13px 10px;
      background-color: #fff;
      border-radius: 7px;

      .infoNameStatus {
        display: flex;

        .infoName {
          display: flex;

          p {
            font-size: 16px;
            color: #333333;
            font-weight: 500;
          }

          img {
            width: 15px;
            height: 5px;
            margin: 5px 10px;
          }
        }

        .infoStatus {
          margin-left: auto;
        }
      }

      .infoTime {
        margin-top: 5px;

        p {
          font-size: 12px;
          color: #999999;
          line-height: 20px;
        }
      }

      .infoDate {
        display: flex;
        margin-top: 5px;

        p:nth-child(1) {
          font-size: 12px;
          color: #999999;
        }

        p:nth-child(2) {
          font-size: 13px;
          color: #333333;
          font-weight: 400;
          margin-left: auto;
        }
      }
    }
  }
}
</style>
